.App {
  text-align: center;
}

.App-header {
  background-color: #c2eaba;
  background-image: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #c2499a;
}

.button {
  background-color: #c6f2df;
  border: 1px solid #46b888;
  border-radius: 20px;
  color: #1f060d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
}
